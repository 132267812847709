import React, { ReactNode, useEffect } from 'react';
import Head from 'next/head';
import defaultTheme from '@mdb/flora/theme';
import '../css/index.scss';
import '../css/_error.scss';
import '../css/case.scss';
import '../css/nav-search.scss';
import '../css/filters.scss';
import '../css/restricted.scss';
import '../css/custom_error.scss';
import { Provider, useSelector } from 'react-redux';
import store from '../components/page/store';
import {
  fetchSessionInitial,
  pushValue,
} from '../components/page/sessionSlice';
import Router, { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { ThemeProvider, Theme } from '@theme-ui/core';
import {
  selectCoveoEngine,
  setCoveoEngine,
} from '../components/page/coveoSlice';
import {
  SearchEngine,
  loadContextActions,
  loadGenericAnalyticsActions,
  loadSearchHubActions,
} from '@coveo/headless';
import Script from 'next/script';

/** Initialize Google Tag Manager */
// Removing GTM for now
// const tagManagerArgs: TagManagerArgs = {
//   gtmId: process.env.GTM ?? '',
// };
// if (process.browser && typeof window) {
//   TagManager.initialize(tagManagerArgs);
// }

const alternativeGTMScript = `!function(e,n){var t=document.createElement("script"),o=null,x="pathway";t.async=!0,t.src='https://'+x+'.mongodb.com/'+(e?x+'-debug.js':''),document.head.append(t),t.addEventListener("load",function(){o=window.pathway.default,(n&&o.configure(n)),o.createProfile("mongodbcom").load(),window.segment=o})}()`;

const DefaultLayout = ({ children }: { children: ReactNode }) => {
  return <div className="needs-layout">{children}</div>;
};

let count = 0;

const ssLog = async (body: any) => {
  await fetch('/api/a', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res: any) => {
      // we don't actually need to handle anything.
      if (res.success) {
        // this is just here to interest blockers.
        count++;
      }
    })
    .catch(err => console.error(err));
};

interface CustomPageProps {
  session: any //for now
  router: any //for now
}

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>) {
  const Layout = (Component as any).Layout || DefaultLayout;

  const router = useRouter();
  const [shouldWeTrack, setShouldWeTrack] = React.useState(false);

  // useEffect(() => {

  // 	if (["/404", "/error", "/"].includes(pageProps.router?.route)) {
  // 		return;
  // 	}
  // 	if (pageProps.session != null) {
  // 		store.dispatch(pushValue(pageProps.session));
  // 	} else {
  // 		store.dispatch(fetchSessionInitial());
  // 	}

  // }, []);

  // useEffect(() => {
  // 	function routeEventHandler(url: any, { shallow }: any) {
  // 		ssLog({
  // 			host:
  // 				typeof window !== "undefined" ? window.location?.hostname : "unknown",
  // 			url: url,
  // 			referer: pageProps.router?.route,
  // 		});
  // 	}
  // 	// Anything in here is fired on component mount.
  // 	Router.events.on("routeChangeComplete", routeEventHandler);
  // 	return () => {
  // 		// Anything in here is fired on component unmount.
  // 		Router.events.off("routeChangeComplete", routeEventHandler);
  // 	};
  // }, [pageProps]);

  /** test */

  useEffect(() => {
    const track =
      window.location.href.includes('support.mongodb.com') &&
        !pageProps?.session?.impersonatedBy?.name
        ? true
        : false;
    setShouldWeTrack(track);
    console.log('Tracking Enabled: ', shouldWeTrack);

    if (['/404', '/error', '/'].includes(pageProps.router?.route)) {
      return;
    }
    if (pageProps.session != null) {
      store.dispatch(pushValue(pageProps.session));
    } else {
      store.dispatch(fetchSessionInitial());
    }

    const url = router.asPath; //pageProps.router?.route;

    //Log on the first load all day everyday.
    let body: any = {
      host:
        typeof window !== 'undefined' ? window.location?.hostname : 'unknown',
      url,
      ...getSessionData(),
    };

    // console and Splunk
    ssLog(body);

    // Coveo Analytics
    dispatchViewPageEvent({ url });
  }, []);

  useEffect(() => {
    //Log on individual route changes
    function routeEventHandler(url: any, { shallow }: any) {
      let body: any = {
        host:
          typeof window !== 'undefined' ? window.location?.hostname : 'unknown',
        url: url,
        referer: router.asPath, // pageProps.router?.route,
        ...getSessionData(),
      };

      // console and Splunk
      ssLog(body);

      // Coveo Analytics
      dispatchViewPageEvent({ url });
    }
    // Anything in here is fired on component mount.
    Router.events.on('routeChangeComplete', routeEventHandler);
    return () => {
      // Anything in here is fired on component unmount.
      Router.events.off('routeChangeComplete', routeEventHandler);
    };
  }, [pageProps]);

  const getHubPageFromUrl = (url: string): string => {
    // Remove empty segments
    const segments = url.split('/').filter(Boolean);

    let hubName = segments[0] || 'default';
 
    //
    // overwrites 
    if(hubName === 'case' && segments[1]?.includes('create?')) { 
      hubName = 'create';
    }
 
    if (url.includes('?')) {
      hubName = hubName.split('?')[0];
    }

    return `${hubName}_page`;
  }

  const getSessionData = (): any => {
    if (!pageProps.session) {
      return {};
    }

    const { id, accounts, visitorId } = pageProps.session;

    return {
      userId: id,
      accountId: accounts.length > 1 ? accounts[0].Id : '',
      accountName: accounts.length > 1 ? accounts[0].Name : '',
      visitorId: visitorId,
    };
  };

  const dispatchViewPageEvent = (data: any) => {
    if (
      typeof window !== 'undefined' &&
      typeof pageProps.session !== 'undefined'
    ) {
      store.dispatch(setCoveoEngine(pageProps.session)).then(() => {
        let engine: any = store.getState().coveo.engine;

        if (engine) {
          if (data.url) {
            const hub = getHubPageFromUrl(data.url);

            engine.dispatch(loadSearchHubActions(engine).setSearchHub(hub));
          }

          const analyticsActionsCreator = loadGenericAnalyticsActions(engine);

          engine.dispatch(
            analyticsActionsCreator.logCustomEvent({
              evt: 'page',
              type: 'view',
            })
          );
        }
      });
    }
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme as Theme}>
        <Head>
          <title>Support Portal</title>
          <link rel="icon" type="image/png" href="/static/favicon.ico" />
          <meta name="theme-color" content="#FFFFFF" />
          <meta
            name="description"
            content="MongoDB Paid Support. MongoDB offers help with training, upgrading, and more"
          />
          {shouldWeTrack && (
            <Script
              id="pathway.js"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: alternativeGTMScript,
              }}
            />
          )}
          <meta content="noindex, nofollow" name="robots"></meta>
        </Head>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;
